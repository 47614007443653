import styled from 'styled-components';

export const FormTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
  text-align: center;
`;

export const FormCard = styled.div<{ isHidden?: boolean }>`
  width: 100%;
  max-width: 460px;
  min-width: 320px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  box-shadow: ${({ theme }) => theme.shadow.medium};
  transition: opacity 250ms ease;
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
`;

export const BottomLink = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  margin-top: 16px;

  a {
    color: ${({ theme }) => theme.color.buttonPrimary};
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
  }
`;

export const ErrorWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.color.error};
  border-radius: 4px;
  padding: 16px;
  font-weight: 500;
  background-color: rgba(255, 25, 74, 0.1);
  text-align: center;
  margin-top: 10px;
`;

export const SuccessWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.color.cta};
  border-radius: 4px;
  padding: 16px;
  font-weight: 500;
  background-color: rgba(48, 168, 79, 0.15);
  text-align: center;
  margin-top: 10px;
`;

export const TextLink = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.color.buttonPrimary};

  &:visited {
    color: ${({ theme }) => theme.color.buttonPrimary};
  }
`;
