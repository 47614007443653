import * as React from 'react';

import { FormCard } from './styles';

const FormWrapper: React.FC<React.PropsWithChildren<{ isHidden?: boolean }>> = ({ children, isHidden }) => {
  return (
    <div className="flex min-h-screen w-full items-center justify-center">
      <div className="flex w-screen flex-col items-center px-4 py-8">
        <span className="mb-12 inline-block h-10 max-w-full">
          <img className="mb-12 inline-block h-10 max-w-full" src="/images/logo.svg" />
        </span>
        <FormCard isHidden={isHidden}>{children}</FormCard>
      </div>
    </div>
  );
};

export default FormWrapper;
